import React from "react";
import {
  ClockIcon,
  StarIcon,
  LinkIcon,
  ChartBarIcon,
  SearchIcon,
  TableIcon,
  PhotographIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/outline";
import {
  StarIcon as SolidStar,
  // SquaresPlusIcon,
  DocumentTextIcon,
  CreditCardIcon,
  UserAddIcon,
  EmojiHappyIcon,
  // ChatIcon,
  ChatAltIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import Image from "next/image";

interface EmptyStateProps {
  header: string;
  subheader: string;
  icon: string;
  background?: string;
  customStyle?: string;
  subheaderCustomPadding?: string;
}

export default function EmptyState({
  header,
  subheader,
  icon,
  background,
  children,
  customStyle,
  subheaderCustomPadding,
}: React.PropsWithChildren<EmptyStateProps>): React.ReactElement {
  const iconStyle = "h-12 w-12 text-gray-400";
  function getIcon() {
    switch (icon) {
      case "thumbs-up":
        return (
          <ThumbUpIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />
        );
      case "star":
        return <StarIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />;
      case "solid-star":
        return <SolidStar className={`mt-8 ${iconStyle}`} aria-hidden="true" />;
      case "search":
        return (
          <SearchIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />
        );
      case "chart":
        return (
          <ChartBarIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />
        );
      case "table":
        return <TableIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />;
      case "clock":
        return <ClockIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />;
      case "quote":
        return (
          <DocumentTextIcon
            className={`mt-8 ${iconStyle}`}
            aria-hidden="true"
          />
        );
      case "payment":
        return (
          <CreditCardIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />
        );
      case "smiley-face":
        return (
          <EmojiHappyIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />
        );
      case "calendar":
        return (
          <div className={`relative ${iconStyle}`}>
            <Image src={"/fill-calendar.svg"} layout="fill" alt="" />
          </div>
        );
      case "contacts":
        return (
          <div className={`relative ${iconStyle} text-gray-400`}>
            <Image
              src={"/fill-contact-icon.svg"}
              layout="fill"
              alt=""
              className={`relative ${iconStyle} text-gray-400`}
            />
          </div>
        );
      case "photo":
        return (
          <div className={`relative ${iconStyle}`}>
            <Image src={"/fill-gallery-icon.svg"} layout="fill" alt="" />
          </div>
        );
      case "photograph":
        return (
          <PhotographIcon
            className={`heroicon-stroke-w-0.8 ${iconStyle}`}
            aria-hidden="true"
          />
        );
      case "connect":
        return <LinkIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />;
      case "chat-bubble-oval-left-ellipsis":
        return (
          <div className={`relative ${iconStyle}`}>
            <Image
              src={"/chat-bubble-oval-left-ellipsis.svg"}
              layout="fill"
              alt=""
            />
          </div>
        );
      case "squares-plus":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className={`${iconStyle}`}
          >
            <path d="M6 3a3 3 0 00-3 3v2.25a3 3 0 003 3h2.25a3 3 0 003-3V6a3 3 0 00-3-3H6zM15.75 3a3 3 0 00-3 3v2.25a3 3 0 003 3H18a3 3 0 003-3V6a3 3 0 00-3-3h-2.25zM6 12.75a3 3 0 00-3 3V18a3 3 0 003 3h2.25a3 3 0 003-3v-2.25a3 3 0 00-3-3H6zM17.625 13.5a.75.75 0 00-1.5 0v2.625H13.5a.75.75 0 000 1.5h2.625v2.625a.75.75 0 001.5 0v-2.625h2.625a.75.75 0 000-1.5h-2.625V13.5z" />
          </svg>
        );
      case "reviews":
        return (
          <div className="flex">
            {[1, 2, 3, 4, 5].map((item) => (
              <SolidStar
                key={item}
                className="mt-8 h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            ))}
          </div>
        );
      case "user-add":
        return (
          <UserAddIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />
        );
      case "dollar":
        return (
          <CurrencyDollarIcon
            className={`mt-8 ${iconStyle}`}
            aria-hidden="true"
          />
        );
      case "chat":
        return (
          <ChatAltIcon className={`mt-8 ${iconStyle}`} aria-hidden="true" />
        );
    }
  }

  return (
    <div
      className={`flex flex-col ${background} justify-center items-center w-full py-8 gap-4 ${
        customStyle && customStyle
      }`}
    >
      {getIcon()}
      <div className="flex flex-col gap-2">
        <h2 className="text-h2-bold text-gray-900 text-center">{header}</h2>
        <p
          className={`text-gray-600 text-body-normal text-center ${
            subheaderCustomPadding ? subheaderCustomPadding : "px-6"
          }`}
        >
          {subheader}
        </p>
      </div>
      {children && children}
    </div>
  );
}
