import React, { Fragment } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon, ShareIcon } from "@heroicons/react/solid";
import { Float } from "@headlessui-float/react";
import {
  CalendarIcon,
  CheckIcon,
  DotsVerticalIcon,
  PhotographIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import Alert from "../../uiwrappers/Alert/Alert";
import GenerateMediaIcon from "../../primaryrender/editor/NewPost/GenerateMediaIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface GenericDropdownProps {
  dropdownItems: any[];
  displayedItem: any;
  width: string;
  customStyle: string;
  children: React.ReactNode;
  noFocus: boolean;
  dropDirection?: string;
  buttonType?: string;
  justifyDirection?: string;
  dropdownItemWidth?: string;
  menuButton?: boolean;
  menuButtonIcon?: string;
  showDotDropdown?: boolean;
  dotsCustomStyle?: string;
  showTrailingCheckIcon?: boolean;
  showTrailingShareIcon?: boolean;
  requiredError?: boolean;
  size?: string;
  disableArray?: boolean[];
  autoPlacement?: boolean;
  disabled?: boolean;
}

function GenericDropdown({
  dropdownItems,
  displayedItem,
  width = "",
  customStyle,
  children,
  noFocus,
  dropDirection,
  buttonType,
  justifyDirection,
  dropdownItemWidth = "lg",
  menuButton = true,
  menuButtonIcon,
  showDotDropdown = false,
  dotsCustomStyle = "",
  showTrailingCheckIcon = false,
  showTrailingShareIcon = false,
  requiredError = false,
  size = "md",
  disableArray,
  autoPlacement = false,
  disabled = false,
}: GenericDropdownProps) {
  function renderIcon(icon: string) {
    if (icon === "plus") {
      return <PlusIcon className="h-5 w-5" />;
    } else if (icon === "calendar") {
      return (
        <CalendarIcon className="h-6 w-6" style={{ strokeWidth: "1.5px" }} />
      );
    } else if (icon === "share") {
      return (
        <div className="bg-green-100 px-2 py-1 rounded-full">
          <ShareIcon className="w-4 h-4 text-green-600" />
        </div>
      );
    } else if (icon === "generateMedia") {
      return <GenerateMediaIcon className="w-5 h-5 text-blue-600" />;
    } else if (icon === "photograph") {
      return <PhotographIcon className="w-5 h-5 text-gray-500" />;
    } else if (icon === "upload") {
      return <UploadIcon className="w-5 h-5 text-gray-500" />;
    }
  }

  function renderMenuItems() {
    const menuItems = dropdownItems.map((item, index) => (
      <Menu.Item
        key={index}
        disabled={
          disableArray && displayedItem["text"] !== item.text
            ? disableArray[index]
            : false
        }
      >
        {({ active, disabled }) =>
          item?.customElement ? (
            <div className="flex justify-between hover:bg-gray-50 active:bg-gray-100 focus:bg-gray-100 flex items-center px-4 py-2 cursor-pointer z-10 h-full">
              {item?.customElement}
            </div>
          ) : (
            <div
              onClick={item.onClick}
              className={classNames(
                "flex w-full",
                "justify-between",
                "hover:bg-gray-50 active:bg-gray-100 focus:bg-gray-100 flex items-center px-4 py-2 cursor-pointer z-10 h-full",
                item?.icon === "plus" &&
                  item?.iconPlacement === "leading" &&
                  "text-blue-600 border-b py-3",
                item.customStyle,
                disabled ? "text-gray-300" : "text-gray-700"
              )}
              data-cy={`${item.text}-dropdown-option`}
            >
              <div className="flex w-full items-center">
                {item?.icon && item?.iconPlacement === "leading" && (
                  <div className="mr-2">{renderIcon(item.icon)}</div>
                )}
                <div className="w-full">{item.text}</div>
                {item?.icon && item?.iconPlacement === "lagging" && (
                  <div className="ml-2">{renderIcon(item.icon)}</div>
                )}
              </div>
              {displayedItem.text === item.text && showTrailingCheckIcon && (
                <CheckIcon className="h-5 w-5 text-blue-600" />
              )}
              {displayedItem.text === item.text && showTrailingShareIcon && (
                <ShareIcon className="h-6 w-8 text-green-600 rounded-full bg-green-50 p-1" />
              )}
            </div>
          )
        }
      </Menu.Item>
    ));
    return menuItems;
  }

  let iconStyle = `flex flex-shrink-0 text-white`;
  let textSize = "";
  let cssStyle = {};
  let menuButtonStyle = "";

  function getIconStyle(size: string) {
    if (size === "xs" || size === "sm") return `h-4 w-4 ${iconStyle} ml-2`;

    if (size === "md") return `h-5 w-5 ${iconStyle} ml-2`;
    else return `h-5 w-5 ${iconStyle} ml-3`;
  }

  switch (size) {
    case "xs":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: "11px",
        paddingRight: "9px",
        paddingTop: "5px",
        paddingBottom: "5px",
      };
      iconStyle = getIconStyle("xs");
      break;
    case "sm":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: "13px",
        paddingRight: "11px",
        paddingTop: "7px",
        paddingBottom: "7px",
      };
      iconStyle = getIconStyle("sm");
      break;
    case "md":
      textSize = "text-sm";
      cssStyle = {
        paddingLeft: "17px",
        paddingRight: "15px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("md");
      break;
    case "lg":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: "17px",
        paddingRight: "15px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("lg");
      break;
    case "xl":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: "25px",
        paddingRight: "23px",
        paddingTop: "13px",
        paddingBottom: "13px",
      };
      iconStyle = getIconStyle("xl");
      break;
    case "dots":
      textSize = "text-sm";
      cssStyle = {
        padding: 0,
      };
  }

  let buttonStyle;
  if (buttonType === "blank") {
    buttonStyle = "";
  } else if (buttonType === "primary") {
    buttonStyle = `${textSize} text-white border border-blue-900 bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 focus:bg-blue-900 transition-colors duration-150 ease-in-out`;
  } else {
    buttonStyle = `${textSize} bg-white font-medium border hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:active:ring-blue-600 focus:bg-white transition-colors duration-150 ease-in-out ${
      requiredError ? "border-red-300" : "border-gray-300"
    } text-gray-700`;
  }
  const justifyStyle = justifyDirection ? justifyDirection : "justify-between";

  const noFocusStyle = noFocus
    ? ""
    : "hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-600";

  if (menuButton) {
    menuButtonStyle = `flex whitespace-nowrap w-full rounded-md border shadow-sm ${
      !showDotDropdown && "px-4 py-2"
    } focus:outline-none ${noFocusStyle} ${customStyle} ${buttonStyle} ${justifyStyle}`;
  } else {
    menuButtonStyle = "flex items-center";
  }

  if (dropdownItemWidth === "xs") {
    dropdownItemWidth = "w-24";
  } else if (dropdownItemWidth === "sm") {
    dropdownItemWidth = "w-32";
  } else if (dropdownItemWidth === "md") {
    dropdownItemWidth = "w-44";
  } else if (dropdownItemWidth === "lg") {
    dropdownItemWidth = "w-80";
  } else if (dropdownItemWidth === "xl") {
    dropdownItemWidth = "w-350";
  } else if (dropdownItemWidth === "w-full") {
    dropdownItemWidth = "w-full";
  } else if (dropdownItemWidth === "w-400") {
    dropdownItemWidth = "w-400";
  }
  if (!dropdownItemWidth) {
    dropdownItemWidth = "w-60";
  }

  function getButtonStyle(buttonType) {
    if (buttonType === "primary") {
      return "text-white";
    } else {
      if (!menuButton) {
        return "text-gray-600 font-medium hover:text-gray-700";
      } else {
        return "text-gray-900 font-normal";
      }
    }
  }

  function getChevronDownStyle(buttonType) {
    if (buttonType === "primary") {
      return "text-white";
    } else {
      if (!menuButton) {
        return "text-gray-500";
      } else {
        return "text-gray-700";
      }
    }
  }

  return (
    <Menu>
      {({ open }) => {
        document.body.classList.toggle("overflow-y-hidden", open);

        return (
          <>
            <Float
              as="div"
              className={width}
              placement={dropDirection || "bottom-end"}
              offset={8}
              floatingAs={Fragment}
              portal={!width}
              autoPlacement={autoPlacement}
            >
              <Menu.Button
                className={menuButtonStyle}
                style={cssStyle}
                onClick={displayedItem.onClick}
                data-cy="generic-dropdown-onClick"
                disabled={disabled}
              >
                {showDotDropdown ? (
                  <DotsVerticalIcon
                    className={`w-auto transform ${
                      dotsCustomStyle
                        ? dotsCustomStyle
                        : "w-5 h-5 text-blue-700"
                    }`}
                  />
                ) : (
                  <div className="flex w-full justify-between">
                    {menuButtonIcon && (
                      <div className="mr-2 text-gray-500 text-medium">
                        {renderIcon(menuButtonIcon)}
                      </div>
                    )}
                    <div
                      className={`flex w-full ${getButtonStyle(buttonType)}`}
                    >
                      {displayedItem.text}
                    </div>
                    <ChevronDownIcon
                      className={`flex justify-self-end font-medium ${iconStyle} ${getChevronDownStyle(
                        buttonType
                      )}`}
                      aria-hidden="true"
                    />
                  </div>
                )}
              </Menu.Button>

              <Menu.Items
                className={`${dropdownItemWidth} ${textSize} max-h-56 overflow-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
              >
                <div className="py-1">{renderMenuItems()}</div>
              </Menu.Items>
            </Float>
            {requiredError && (
              <Alert alertMessage="*Required field" customStyle="mt-1" />
            )}
          </>
        );
      }}
    </Menu>
  );
}

GenericDropdown.defaultProps = {
  dropdownItems: [
    {
      text: "",
      onClick: () => {},
    },
  ],
  displayedItem: {
    text: "",
    onClick: () => {},
  },
  width: "",
  customStyle: "",
  children: null,
  noFocus: false,
};

export default GenericDropdown;
